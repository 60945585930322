import React from "react"
import { Helmet } from 'react-helmet'
import logo from '../images/logo.png'; 
import '../libraries/bootstrap-3.3.6-dist/css/bootstrap.min.css';
import '../libraries/font-awesome-4.6.1/css/font-awesome.min.css';
import '../css/kotus_scss.css';
import '../css/kotus.css';
import './index.css';




const IndexPage = () => (
<div className="smoothscroll under-construction">

<div className="page-wrapper">

    <div className="main">

        <section className="title">
            <i className="fa fa-cogs"></i>
            <h1>Stránka bude čoskoro hotová</h1>
        </section>

        <section className="introduction">
            <div className="container">
                <div className="introduction-box">
                    <div className="row">

                        <div className="col-sm-6">
                            <div className="contact-box">
                                <dl>
                                    <dd><a href="tel:+421556771044">+421 55 677 10 44</a></dd>
                                    <dt><i class="fa fa-phone"></i></dt>
                                </dl>
                                <dl>
                                    <dd><a href="mailto:office@ak-kotus.sk">office@ak-kotus.sk</a></dd>
                                    <dt><i class="fa fa-envelope"></i></dt>
                                </dl>
                                <dl>
                                    <dd>Masarykova 12, 040 01 Košice,<br/>Slovenská republika</dd>
                                    <dt><i class="fa fa-flag"></i></dt>
                                </dl>
                            </div>
                        </div>

                        <div className="col-sm-6">
                            <img src={logo} alt="kotus logo" className="logo"/>
                        </div>

                    </div>
                </div>
            </div>
        </section>

    </div>
</div>

<Helmet title="Advokátska kancelária a správcovská Kotus" defer={false} />

</div>
)

export default IndexPage
